export default {
  namespaced: true,
  state: {
    isUserPersonalSidebarOpen: false,
  },
  getters: {},
  mutations: {
    UPDATE_USER_PERSONAL_SIDEBAR_OPEN(state, val) {
      state.isUserPersonalSidebarOpen = val
    },
  },
  actions: {},
}
