export default [
  // user
  // Dashboard
  {
    path: '/user/dashboard',
    name: 'user-dashboard',
    component: () => import('@/views/apps/user/dashboard/Dashboard.vue'),
    meta: {
      role: ['user'],
      contentClass: 'user-dashboard',
      navActiveLink: 'user-dashboard',
    },
  },
  

  // Classroom
  {
    path: '/trainings',
    name: 'user-trainings.index',
    component: () => import('@/views/apps/user/training/Index.vue'),
    meta: {
      role: ['user'],
      contentClass: 'user-trainings.index',
      navActiveLink: 'user-trainings.index',
      pageTitle: 'Pelatihan',
      breadcrumb: [
        {
          text: 'Pelatihan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/trainings/:trainingId',
    name: 'user-trainings.show',
    component: () => import('@/views/apps/user/training/classroom-detail/ClassroomDetail.vue'),
    meta: {
      role: ['user'],
      contentClass: 'user-trainings.index',
      navActiveLink: 'user-trainings.index',
      pageTitle: 'Detail Pelatihan',
      back: 'trainings.index',
      breadcrumb: [
        {
          text: 'Pelatihan',
          to: 'trainings.index',
        },
        {
          text: 'Detail Pelatihan',
          active: true,
        },
      ],
    },
  },
  // chapters
  /* {
    path: '/trainings/:trainingId/chapters/:chapterId',
    name: 'user-chapters.show',
    component: () => import('@/views/apps/user/training/classroom-material/ClassroomMaterial.vue'),
    meta: {
      role: ['user'],
      contentClass: 'user-trainings.index',
      navActiveLink: 'user-trainings.index',
      pageTitle: 'Kelas',
      back: 'classroom-detail',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          to: 'classroom-detail',
        },
        {
          text: 'Materi',
          active: true,
        },
      ],
    },
  }, */
  /* {
    path: '/trainings/:trainingId/chapters/create',
    name: 'user-chapters.create',
    component: () => import('@/views/apps/user/training/classroom-material/classroom-material-add/ClassroomMaterialAdd.vue'),
    meta: {
      role: ['user'],
      contentClass: 'user-classroom-menu',
      navActiveLink: 'user-classroom',
      pageTitle: 'Kelas',
      back: 'classroom-detail-material',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          to: 'classroom-detail',
        },
        {
          text: 'Materi',
          to: 'classroom-detail-material',
        },
        {
          text: 'Tambah',
          active: true,
        },
      ],
    },
  }, */

  // Classroom Task
  {
    path: '/trainings/:trainingId/chapters/:chapterId/tasks',
    name: 'user-chapter-tasks.show',
    component: () => import('@/views/apps/user/training/classroom-task/DetailTask.vue'),
    meta: {
      role: ['user'],
      contentClass: 'user-trainings.index',
      navActiveLink: 'user-trainings.index',
      pageTitle: 'Tugas',
      back: 'trainings.show',
      breadcrumb: [
        {
          text: 'Pelatihan',
          to: 'trainings.index',
        },
        {
          text: 'Detail Pelatihan',
          to: 'trainings.show',
        },
        {
          text: 'Tugas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/trainings/:trainingId/chapters/:chapterId/tasks/:taskId/multiplechoice-questions',
    name: 'user-task-multiplechoice-questions.show',
    component: () => import('@/views/apps/user/training/classroom-task/task-question/MultipleChoice.vue'),
    meta: {
      role: ['user'],
      contentClass: 'user-trainings.index',
      navActiveLink: 'user-trainings.index',
      pageTitle: 'Tugas',
      back: 'chapter-tasks.show',
      breadcrumb: [
        {
          text: 'Pelatihan',
          to: 'trainings.index',
        },
        {
          text: 'Detail Pelatihan',
          to: 'trainings.show',
        },
        {
          text: 'Tugas',
          to: 'chapter-tasks.show',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/trainings/:trainingId/chapters/:chapterId/tasks/:taskId/essay-questions',
    name: 'user-task-essay-questions.show',
    component: () => import('@/views/apps/user/training/classroom-task/task-question/Essay.vue'),
    meta: {
      role: ['user'],
      contentClass: 'user-trainings.index',
      navActiveLink: 'user-trainings.index',
      pageTitle: 'Tugas',
      back: 'chapter-tasks.show',
      breadcrumb: [
        {
          text: 'Pelatihan',
          to: 'trainings.index',
        },
        {
          text: 'Detail Pelatihan',
          to: 'trainings.show',
        },
        {
          text: 'Tugas',
          to: 'chapter-tasks.show',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/trainings/:trainingId/chapters/:chapterId/tasks/create',
    name: 'user-chapter-tasks.create',
    component: () => import('@/views/apps/user/training/classroom-task/classroom-task-add/ClassroomTaskAdd.vue'),
    meta: {
      role: ['user'],
      contentClass: 'user-trainings.index',
      navActiveLink: 'user-trainings.index',
      pageTitle: 'Tambah Tugas',
      back: 'trainings.show',
      breadcrumb: [
        {
          text: 'Pelatihan',
          to: 'trainings.index',
        },
        {
          text: 'Detail Pelatihan',
          to: 'trainings.show',
        },
        {
          text: 'Tambah Tugas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/certificates',
    name: 'user-certificates.index',
    component: () => import('@/views/apps/user/certificate/Index.vue'),
    meta: {
      role: ['user'],
      contentClass: 'user-trainings.index',
      pageTitle: 'Sertifikat',
      breadcrumb: [
        {
          text: 'Sertifikat',
          active: true,
        },
      ],
    },
  },
  {
    path: '/certificates/:certificateId',
    name: 'user-certificates.show',
    component: () => import('@/views/apps/user/certificate/Show.vue'),
    meta: {
      role: ['user'],
      contentClass: 'user-certificates.index',
      navActiveLink: 'user-certificates.index',
      pageTitle: 'Detail Sertifikat',
      back: 'certificates.index',
      breadcrumb: [
        {
          text: 'Sertifikat',
          to: 'certificates.index',
        },
        {
          text: 'Detail Sertifikat',
          active: true,
        },
      ],
    },
  },


  {
    path: '/training-requests',
    name: 'user-training-requests.index',
    component: () => import('@/views/apps/user/training-request/Index.vue'),
    meta: {
      role: ['user'],
      contentClass: 'user-training-requests.index',
      pageTitle: 'Request Pelatihan',
      breadcrumb: [
        {
          text: 'Request Pelatihan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/training-requests/create',
    name: 'user-training-requests.create',
    component: () => import('@/views/apps/user/training-request/Create.vue'),
    meta: {
      role: ['user'],
      contentClass: 'user-training-requests.index',
      pageTitle: 'Request Pelatihan Baru',
      breadcrumb: [
        {
          text: 'Request Pelatihan Baru',
          active: true,
        },
      ],
    },
  },
  {
    path: '/training-requests/:trainingRequestId',
    name: 'user-training-requests.show',
    component: () => import('@/views/apps/user/training-request/Show.vue'),
    meta: {
      role: ['user'],
      contentClass: 'user-training-requests.index',
      navActiveLink: 'user-training-requests.index',
      pageTitle: 'Detail Request Pelatihan',
      back: 'training-requests.index',
      breadcrumb: [
        {
          text: 'Request Pelatihan',
          to: 'training-requests.index',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/training-requests/:trainingRequestId/edit',
    name: 'user-training-requests.edit',
    component: () => import('@/views/apps/user/training-request/Edit.vue'),
    meta: {
      role: ['user'],
      contentClass: 'user-training-requests.index',
      navActiveLink: 'user-training-requests.index',
      pageTitle: 'Edit Request Pelatihan',
      back: 'training-requests.index',
      breadcrumb: [
        {
          text: 'Request Pelatihan',
          to: 'training-requests.index',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
]
